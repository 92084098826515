export const tutorialsList = [
  {
    title: "Creating & Editing Matters",
    tutorials: [
      {
        title: "Create a New Matter to Save Your Work",
        video: "https://www.youtube.com/embed/ykWADIFkzXY",
        steps: `
<p>In this tutorial we cover how to create a new matter to save your work.</p>
<p>  Matters function as a way to keep your work organised and provide you with a space to save useful case detail to assist you as the claim progresses. </p>
<p>There are two ways to create a new matter:</p>
<ol>
<li> From the My Matters menu click on +New Matter in the top right hand side of the screen.</li>
<li> Start a new Damages Calculator or Economic Loss Analyser from the quick start buttons in the top right of the screen. </li>
</ol>
<p>When inside those tools click the Save button and you'll have the option to save to an existing matter or save to a new matter.</p>
<p>If you choose to save to a new matter you'll just need to enter a matter name and that new matter will be created and the Damages Calculator or Economic Loss Analyser you were working on will be saved to that new matter. </p>
<p>In each case you only have to enter a matter name for a new matter. This can be anything. And you can go back an edit it and any other field in the matter by clicking on the edit icon next to the matter name.</p>
<p>One of the design aims of LawLite is to enable lawyers to have the case details and the ability to do excellent analysis wherever they are - home, office, court, mediation. The matter page serves as a kind of summary cover page where you can record case details for quick reference.</p>
        `,
      },
      {
        title: "Edit a Matter",
        video: "https://www.youtube.com/embed/RuMilECz4Bc",
        steps: `
<p>In this tutorial we will cover how to edit the fields and name of an existing matter.</p>
<p>From the My Matters menu click on the matter that you want to edit. </p>
<p>Click on the edit icon next to the matter name.</p>
<p>This then allows you to edit all the fields of the matter, including the matter name.</p>

        `,
      },
      {
        title: "Archive, Unarchive and Delete Matters",
        video: "https://www.youtube.com/embed/aBNs2W4x0yQ",
        steps: `
<p>In this tutorial we will cover how to Archive, unarchive and delete matters.</p>
<p>From the My Matters menu click on the matter that you want to archive.</p>
<p>Click on the edit icon next to the matter name.</p>
<p>Down the bottom of the screen you will see an 'Archive' button. Click this button to archive the matter.</p>
<p>The status of the matter in the top right of the page will now change from Active to Archived. </p>
<p>To unarchive or delete a matter, from the My Matters screen click on the 'Status' drop down menu. Select 'Archived'.  </p>
<p>Click on the matter that you want unarchive. </p>
<p>Click on the edit icon next to the matter name.</p>
<p>You will then have buttons at the bottom right of the screen to 'Delete' or 'Unarchive' the matter. Click the action that you want.</p>
        `,
      },
    ],
  },
  {
    title: "Economic Loss Analyser",
    tutorials: [
      {
        video: "https://www.youtube.com/embed/wkYRj7uN4oo",
        title:
          "Create a New Economic Loss Analyser and Overview of User Interface",
        steps: `
       <p> In this tutorial we cover the two ways that you can start working the LawLite Economic Loss Analyser:</p>
      <ol>
        <li>Click the 'Eco Loss Analyser' button at the top right of the My Matters menu screen (make sure hit Save if you want to keep your work)</li>
        <li> Open a Matter and click on 'New' to start an Economic Loss Analyser.</li>
        </ol>
  <p>Economic Loss Analyser screen divides into three portions:</p>
  <p>Right: Entering, editing, modifying and annotating Earnings Periods for Claimant and Comparison Data Sets.</p>
        <p>Middle: Displays the earnings data and the effects of any modifications for easy analysis. </p>
        <p>The Graph displays your analysis visually for easy comparison and illustration, while all the numbers are available for Claimant and Comparison via the drop down buttons on the Claimant and Comparison bars below. </p>
        <p>Narrow the period of the data that will used by editing the period just under the Graph. </p>
        <p>Export the Graph or export a professionally presented Economic Loss Report showcasing all your analysis in an easy to read format. </p>
        <p>Left: Perform loss calculations. Choose the loss period, estimate the impairment, choose an impairment percentage for the Loss Line, and see the gross and net losses calculated.</p>
          `,
      },
      {
        video: "https://www.youtube.com/embed/zVgbu3iSQX0",
        steps: `<p>In this tutorial we cover how to add an Earnings Period using your own earnings history data (e.g. typically obtained from a plaintiff's tax records).</p>
        <p>Go to the right hand side 'Add/Edit Earnings Periods'</p>
        <p>Click the drop down to choose whether to edit Claimant or Comparison Earnings.</p>
        <p>Click the 'Add Earnings Period' button.</p>
        <p>Choose the date range of the Earnings Period (don't worry you can  edit this later)</p>
        <p>Click the 'Source' drop down and select 'Upload / Manual Entry'</p>
        <p>Click 'Next'</p>
        <p>Click on Manual Entry option if you want to type the earnings data for each year directly into the app.</p>
        <p>Click the Upload Data option if you want to drag an Excel file with the earnings data into the Economic Loss Analyser. We recommend that you use one of the templates provided (i.e. 'Download Excel Template') when loading earnings data this way. [Note: If you have any gaps in your earnings data just leave that cell blank, you'll have the option to calculate an estimate to patch any gaps]</p>
        <p>If there are any gaps in your earnings data the Economic Loss Analyser will ask if you would like to patch those gaps with an estimate (it uses an extended version of the midpoint formula to produce good estimates - so good you can't tell!).</p>
        <p>Make sure that if you have entered in Gross Weekly Earnings rather than Gross Yearly Earning that you use the drop down menus above each column in the 'Load Earnings Data' box to tell the app so it knows how to interpret the data.</p>
        <p>Then click next. </p>
        <p>A new Earnings Period will appear on the right hand side of the screen. You can edit it by clicking on the drop down button, and you can edit the label.</p>
        <p>If your earnings data had any blanks for the tax paid in any financial year then this will be automatically calculated by the app. </p>
        <p>You can see in the drop down for the Claimant or Comparison Earnings Data Set (which ever one you added the Earnings Period to) all the gross earnings, tax, net earnings and superannuation (if added) figures for each financial year.</p>
        <p>You can add superannuation to the Claimant or Comparison Earnings Data Sets by Clicking on the 'Add Superannuation' button. This also allows you to chose superannuation rates for each financial year (we include the minimum rates by default from 2002).</p>`,
        title:
          "Adding an Earnings Period: Uploading Your Own Earnings Data via Manual Entry or Excel File",
      },
      {
        video: "https://www.youtube.com/embed/iDuU_6489gA",
        steps: `<p>In this tutorial we cover how to add an Earnings Period using the built in Australian Bureau of Statistics Average Weekly Earnings data sets.</p>
        <p>Go to the right hand side 'Add/Edit Earnings Periods'.</p>
        <p>Click the drop down to choose whether to edit Claimant or Comparison Earnings.</p>
        <p>Click the 'Add Earnings Period' button.</p>
        <p>Choose the date range of the Earnings Period (don't worry you can  edit this later)</p>
        <p>Click the 'Source' drop down and select 'ABS Average Weekly Earnings'.</p>
        <p>If your date range exceed the range of the AWE data set a note will appear so that you can change the date range of the Earnings Period.</p>
        <p>Click Next.</p>
        <p>Choose the Sex, Industry, and Data set (Full Time Total, Full Time Ordinary, Total) and click Ok. </p>
        <p>A new Earning Period will appear on the right hand side. </p>
        <p>You can edit the parameters of the Earnings Period via its drop down button.</p>
        <p>You can add superannuation to the Claimant or Comparison Earnings Data Sets by Clicking on the 'Add Superannuation' button. This also allows you to chose superannuation rates for each financial year (we include the minimum rates by default from 2002).</p>`,
        title:
          "Adding an Earnings Period: Loading an ABS Average Weekly Earnings Data Set",
      },
      {
        video: "https://www.youtube.com/embed/NyL42IRxkCI",
        steps: `<p>In this tutorial we cover how to add an Earnings Period using an estimate.</p>
        <p>This is particularly useful if you want to forecast future earnings or losses.  </p>
        <p>Go to the right hand side 'Add/Edit Earnings Periods'.</p>
        <p>Click the drop down to choose whether to edit Claimant or Comparison Earnings.</p>
        <p>Click the 'Add Earnings Period' button.</p>
        <p>Choose the date range of the Earnings Period (don't worry you can  edit this later)</p>
        <p>Click the 'Source' drop down and select Estimate Data.</p>
        <p>Click Next.</p>
        <p>Choose the parameters: </p>
        <p>Start Amount (the base weekly or yearly earnings for the first year of the Earnings Period) and choose whether it is Weekly or Yearly Earnings.</p>
        <p>Increase by (whether the earnings are to grow/decline year on year by a percentage or dollar value).</p>
        <p>Percentage/Dollar Value: the percentage or dollar value that the earnings period increases/decreases by each year.</p>
        <p>Apply from: choose whether the percentage/dollar value is applied from the first year or second year on wards for the estimated Earnings Period (this is useful if you want an Estimated Earnings Period to continue on smoothly from a previous Earnings Period. You just use the last value of the previous Earnings Period as the Start Amount, and choose to Apply from 'First Year'). </p>
        <p>Then click 'Ok'.</p>
        <p>A new Earnings Period will appear on the right hand side. You can edit its parameters by clicking on its drop down button. You can also give it a custom label.</p>
        <p>You can add superannuation to the Claimant or Comparison Earnings Data Sets by Clicking on the 'Add Superannuation' button. This also allows you to chose superannuation rates for each financial year (we include the minimum rates by default from 2002).</p>
        `,
        title:
          "Adding Earnings Periods: Using Estimated Earnings (Useful for Projecting Future Earnings)",
      },
      {
        video: "https://www.youtube.com/embed/V034EQrYqE8",
        steps: `<p>In this tutorial we cover how to slot an Earnings Period in before an existing Earnings Period.</p>
        <p>Go to the right hand side 'Add/Edit Earnings Periods'.</p>
        <p>Click the drop down to choose whether to edit Claimant or Comparison Earnings.</p>
        <p>Click the 'Add Earnings Period' button.</p>
        <p>Choose the date range of the Earnings Period (don't worry you can  edit this later)</p>
        <p>It will give you a warning if you are about to overlap with the other Earnings Period. Note the date range of that Earnings Period in the warning and adjust your selected date range until the warning disappears. </p>
        <p>Then continue selecting the parameter for the earnings data that you want to use for that Earnings Period:</p>
        
        <ol>
        <li>Add Earnings Period by uploading your own data: <a href="https://www.youtube.com/watch?v=zVgbu3iSQX0">https://www.youtube.com/watch?v=zVgbu3iSQX0</a></li>
        <li>Add Earnings Period by Loading an ABS Average Weekly Earnings data set: <a href="https://www.youtube.com/watch?v=iDuU_6489gA">https://www.youtube.com/watch?v=iDuU_6489gA</a></li>
        <li>Add Earnings Period by using an Estimate: <a href="https://www.youtube.com/watch?v=NyL42IRxkCI">https://www.youtube.com/watch?v=NyL42IRxkCI</a></li>
        </ol>`,
        title:
          "Adding Earnings Periods: Slotting an Earnings Period in Before an Existing Earnings Period",
      },
      {
        video: "https://www.youtube.com/embed/I9AmoXvLXP0",
        steps: `<p>In this tutorial we show how to edit existing Earnings Periods in the Claimant or Comparison Data Sets.</p>
        <p>Go to the right hand side under 'Add/Edit Earnings Periods'.</p>
        <p>Select Claimant or Comparison from the drop down menu.</p>
        <p>Click on the drop down button on the right of any Earnings Period to edit the parameters.</p>
        `,
        title: "Editing Existing Earnings Periods",
      },
      {
        video: "https://www.youtube.com/embed/Beo1a2VfdJ4",
        steps: `<p>In this tutorial we will cover the use of Modifers to model events in the Claimant or Comparison earnings. </p>
        <p>This is really powerful feature. Model promotions, absences, periods of reduced earnings, and add annotations to the Graph. </p>
        <p>Start by heading to the right hand side 'Add/Edit Earnings Periods'.</p>
        <p>Click the 'Add Modifier' button that is part way down the page under the Superannuation button.</p>
        <p>You can give it a label by clicking on the button in the top left of the Modifier box. </p>
        <p>Select a period (the first and last years that the Modifier will apply for).</p>
        <p>Select whether to increase/decrease by percentage or dollar value (to decrease just enter the '-' prefix i.e. -30 rather than 30).</p>
        <p>chose the value of the percentage or dollar amount to modify the earnings data by for the selected period.</p>
        <p>Write a short note in 'Comments' and click 'Add as note on graph' to add the comment as an annotation on the graph. If you change the comment just click 'remove note from graph' and then re-add it to update the graph.</p>
        <p>Note the 'Loss line to ignore this Modifer' switch. This is on by default. This means that when the Loss Line computes its value it treats the unmodified values of the Comparison Earnings set as 100% of earning capacity. it means that if you add in expected earnings increases to the Comparison set the loss line will ignore these (which is what you will usually want to do). </p>
        <p>You can also use Modifies to add annotations to the Graph. Just click 'Add Modifier, then chose the period, then use the Comments box to add an annotation.</p>
        `,
        title: "Adding & Editing Modifiers and Graph Annotations",
      },
      {
        video: "https://www.youtube.com/embed/mc_OmsnMszw",
        steps: `<p>In this tutorial we will cover how to do the actual economic loss calculations and the features that we have built into assist you.</p>
        <p>This all happens on the left hand side under 'Economic Loss Calculations'. </p>
        <p>Set the Loss Period - which is the period over which you want to calculate the loss. Typically you would start from the year in which the injury occurred.</p>
        <p>Switch the Loss Line to 'On'. You'll then see the Median and Average Impairment percentage estimates appear. </p>
        <p>In economic loss claims you are generally arguing (or dealing with an argument) that the claimant has suffered impairment to their earning capacity. The Comparison Earnings are what you say they would have earned but for the injury. If the Claimant is earning 70% of the value of the Comparison earnings as a result of the injury then they have a 30% impairment to their earning capacity. That means that the gap between the Comparison and Claimant earnings for any year will be about 30% of the value of the Comparison earnings for that year. </p>
        <p>The median and Average impairment features give you the median and average values of the gap between Comparison and Claimant earnings for the selected Loss Period. This gives you a guide to the degree to which the claimant's earning capacity has been impaired. </p>
        <p>You can use these estimates to then set the value for the Loss Line. This feature allows you to then calculate the loss based only on what is attributable to the impairment caused by the defendant - any dips in earnings below that are ignored.</p>
        <p>At the bottom left hand side you can see the Loss Value calculations. You can toggle the drop down to get the Gross or Net. If you have added superannuation to the Comparison and Claimant sets then it will give you the loss superannuation as well.</p>
        `,
        title:
          "Performing Loss Calculations: Setting the Loss Period, Using Impairment Estimates & Loss Line",
      },
      {
        video: "https://www.youtube.com/embed/vJioHgO7l7Q",
        steps: `<p>In this tutorial we cover how to export your analysis. </p>
        <p>Once you've done your economic loss analysis you can go to the center portion and click on 'Export Eco Loss Report'.</p>
        <p>This will cause a report to be generated from your analysis. It will appear as a MS Word document to download. </p>
        <p>Open or save the document. </p>
        <p>When you open it, click on 'Enable Editing' in the yellow ribbon at the top of the the MS Word window. This will allow the table of contents to be generated. </p>
        <p>Scroll through the report. You'll see that it sets out your loss calculations, you annotated graph, all the earnings figures used and notes on any modifications and estimates that were used. At the end of the report the are notes explaining the various calculation methods that have been applied. </p>
        <p>This report is a great resource for providing your analysis to other members of your legal team, to counsel, to opponents and mediators.</p>
        `,
        title:
          "Export an Economic Loss Report (Generated from your Economic Loss Analysis)",
      },
    ],
  },
  {
    title: "Damages Calculator",
    tutorials: [
      {
        video: "https://www.youtube.com/embed/22mS_pI0FWk",
        steps: `<p>In this tutorial we cover how to get started working with the LawLite Damage Calculator and save you work.<p>
        <p>There are two ways to get started. <p>
        <ol>
        <li><p> From the My Matters menu click on the Damages Calculator quick launch button in the top right hand side.</p>
        <p>This will launch you straight into a fresh Damages Calculator. This is a useful feature if you want to just get started, run some calculations and then close it without saving.</p>
        <p>If you want to save you work, just go to the drop down section of the Save button at the bottom right of the page, select 'save to an existing or new matter'. You'll then have the option to save the Damages Calculator to an existing matter or to create a new matter to save it to.</p>
        </li>
        <li> <p>Open (or create and then open a new matter: see tutorial on creating new matters - https://www.youtube.com/watch?v=ykWAD...) a matter. </p>
        <p>Click on the 'New' button in the bottom right of the page. </p>
        <p>Select a new Damages Calculator. This will create a Damages Calculator for this matter. When you click the save button on this Damages Calculator it will save to that matter. </p>
        </li>
        </ol>
        <p>The user interface for the Damages Calculator is divided in to three portions. </p>
        <p>On the right you have all the Heads of Damages (or claim types) and Other Elements that you can add into create or edit a Schedule of Damages. </p>
        <p>In the centre of the page is where all the calculations happen. </p>
        <p>Here you can open each head of damages that you are using and enter the parameters. </p>
        <p>You can use the blue switches to toggle each head of damages on or off.</p>
        <p>You can set discounts for each head of damages of for the claim over all.</p>
        <p>You can delete heads of damages.</p>
        <p>You can change the label of each head of damages by clicking on the edit icon next to the name of each head of damages. </p>
        <p>You can save your work.</p>
        <p>You can export your calculations to MS Word using the 'Export' button.</p>
        `,
        title: "Start a New Damages Calculator and Overview of User Interface",
      },
      {
        video: "https://www.youtube.com/embed/OLuycxGSyTc",
        steps: `<p>In this tutorial we will show how you can start making a schedule of damages in the Damages Calculator. </p>
        <p>To get started you can either start dragging in heads of damages (or selecting multiple heads and clicking the Add button) from the right hand side. Or you can select a built in template schedule of damages or one of your custom schedule templates from the left hand side.</p>
        <p>From there it is a simple drag and drop exercise. You can grab and drag heads of damages up and down the schedule to reorder them.</p>
        <p>You can edit the names of the different heads of damages by clicking on the edit icon next to the name of each head of damages you are using. </p>
        <p>You can add multiples of the same head of damages if you need to.</p>
        <p>Where a head of damages is just a lump sum you can just enter the value directly into the field with the dollar sign. Otherwise, just click on the drop down button for each head of damages to enter in the parameters. </p>
        <p>Note that if you want to, you can override the calculated value of a head of damages by simply entering in a new value over the calculated value in the field with the dollar sign (same as you would do if entering in the value for a lump sum claim like General Damages). </p>
        <p>You can delete any head of damages from your schedule of damages by clicking on the garbage bin (trash can) icon next to the blue switch on that head of damages. </p>`,
        title: "Making & Editing a Schedule of Damages",
      },
      {
        video: "https://www.youtube.com/embed/d_fdRRSimZ8",
        steps: `<p>In this tutorial we cover how you can create more multiple Damages Calculators per matter.</p>
        <p>Once you have created a Damages Calculator and entered some values in and saved it, you can then consider creating some more Damages Calculators to run different scenarios.</p>
        <p>Often you may want to have 3-4 Damages Calculators, for upper, lower and most likely claim valuations, and a 4th for checking your opponent's assessment of the claim.</p>
        <p>Go to the top left of the page and click on the name of your matter ([Home Icon] / Matters / [Name of Your Matter] / Damages Calculator). </p>
        <p>This will take you back to the matter screen for this matter. </p>
        <p>Click on the New button. You now have the option to create a new Damages Calculator (which is a clean slate) or to create new one that is a copy of an existing Damages Calculator. </p>
        <p>The ability to make new Damages Calculators that copy over the calculations of an existing Damages Calculator is a time saver. Chances are that additional Damages Calculators will just be variations of existing ones with changes to one or more heads of damages. So this saves you entering everything in again. </p>
        <p>If you chose to make a new one that is a copy of an existing Damages Calculator it will ask you to choose which existing Damages Calculator to copy from a drop down list. </p>
        <p>You'll see once you have created a new Damages Calculator as a copy it will be the same as the existing one, but you can now take it off in a new direction by changing the heads of damages or editing the parameters of the different heads of damages.</p>
        `,
        title: "Create Multiple Damages Calculators per Matter",
      },
      {
        video: "https://www.youtube.com/embed/AQWimg82Rg4",
        steps: `<p>In this tutorial we will cover how you can use the Costs and Deductions elements to advise clients on the total cost or net benefit of offers made in settlement negotiations. </p>
        <p>From the Other Elements section on the right hand side, drag in a Costs element. A value entered into a Costs element will be added to the Total. You'll see this update as you start punching the value in. </p>
        <p>This is useful for generating an offer that includes the costs value, or for defendants advising clients on the total cost of an offer (i.e. you can include your costs in this figure and perhaps use another Costs element to enter in the anticipated portion of the plaintiff's costs they may be up for too if you like). </p>
        <p>You can use the blue switch to toggle the Costs element on and off to add and remove it's value from the Total.</p>
        <p>From the Other Elements section on the right grab and drag in a Deductions element. Values entered into this element will be subtracted from the Total. This can be useful for factoring in set-off amounts or for plaintiff lawyers wanting to show the client the net benefit (after deduction of legal costs and disbursements) of an offer if accepted.</p>`,
        title:
          "Using Costs & Deductions for Set-Off, or to Advise Clients on Cost or Net Benefit of Offers",
      },
      {
        video: "https://www.youtube.com/embed/MVxqYc-zEWk",
        steps: `<p>In this tutorial we will cover how you can apply discounts to individual heads of damages or to the entire claim.</p>
        <p>You'll see down the side of each head of damages is a percentage (it will start with a value of 100%) and this appears also in the Total. This is the percentage of the full value of the claim that is attributable to the defendant (i.e. the portion of the full value that they are liable to pay). </p>
        <p>You can apply discounts to individual heads of damages by changing the percentage figure for that head of damages (i.e. To discount by 30% you would change the value to 70% attributable). </p>
        <p>Similarly, you can change the percentage value in the Total. This will discount the entire claim. </p>
        <p>In each scenario the Damages Calculator will display the full value with strike-through and then the value that the defendant is said to be liable to pay.</p>
        `,
        title: "Discounting the Entire Claim or Individual Heads of Damages",
      },
      {
        video: "https://www.youtube.com/embed/4h2mmZKs2Yg",
        steps: `<p>In this tutorial we will cover how to use Template Schedules of Damages.</p>
        <p>Once you have created a schedule of damages, go to the Save button at the bottom. Click Save to save your work. Then hover over the drop down part of the Save button. This will show the option to 'save as template'. Click this option and a template schedule of damages will be created and added to the top left side of the Damages Calculator screen under 'My Schedules'. It will use the same name that you have given to the Schedule of Damages that the template was produced from (you can change this name before generating the template by clicking on the edit icon next to the name of the Schedule of Damages at the top). </p>
        <p>You can delete a template that you have made by selecting it and clicking on the delete button that appears.</p>
        <p>You will see if you start a new Damages Calculator that you can load your custom template to get started.</p>
        <p>To use a built in template go to the 'Standard Schedules' section on the left hand side. Choose the Schedule that you want to load and click on Open.</p>
        <p>The Damages Calculator will warn you if you are about to write over any existing calculations.</p>
        `,
        title:
          "Using Template Schedules of Damages Loading the Built in Templates and Making and Loading Your Ow",
      },
      {
        video: "https://www.youtube.com/embed/OPwn8T8YsO8",
        steps: `
        <p>In this tutorial we will cover how to export your Schedule of Damages to MS Word. </p>
        <p>Once you have done your calculations click on Save and go to the Export button at the bottom of the page. I you hover over the drop down portion of the Export button you will have two options, the first exports in the standard format showing your calculations and the second exports in a table format showing the heads of damages and their value in a MS Word table without the calculations. </p>
        <p>Choose which format you would like, click on it, and your browser will download the schedule of damages as a MS Word document.</p>`,
        title: "Exporting your Damages Calculations to MS Word",
      },
    ],
  },
]
