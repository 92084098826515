import React, { ReactElement } from "react"
import { MediaRender } from "../components/HomePage/NewsAndUpdates"
import DefaultLayout from "../templates/DefaultLayout"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"
import Youtube from "../image/logo/youtube.png"
import Input from "../components/Input/Input"
import { tutorialsList } from "../constants/tutorialsList"
import Seo from "../components/seo"
interface Props {}

const Accordion = ({
  children,
  title,
  small = false,
  search,
}: {
  children?: React.ReactNode
  title: string
  small?: boolean
  search?: string
}) => {
  const [open, setOpen] = React.useState(search && !small ? true : false)

  return (
    <div className="mt-3">
      <div
        onClick={() => {
          setOpen(!open)
        }}
        className={`border  px-4 cursor-pointer flex items-center   text-br-primary-blue ${
          small ? "lg:text-base text-sm py-3" : "lg:text-lg text-base py-5"
        }`}
      >
        {open ? (
          <ChevronUpIcon
            className={`
             ${small ? "h-6" : "h-8"}  border rounded-md mr-3 flex-shrink-0`}
          />
        ) : (
          <ChevronDownIcon
            className={`${
              small ? "h-6" : "h-8"
            }  border rounded-md mr-3 flex-shrink-0`}
          />
        )}
        <span
          dangerouslySetInnerHTML={{
            __html: title.replace(
              new RegExp(`(${search || ""})`, "ig") || "",
              `<span class="bg-br-primary-blue bg-opacity-20 font-bold">$1</span>`
            ),
          }}
        ></span>
      </div>
      {open && <div className="border border-t-0 px-4 py-4">{children}</div>}
    </div>
  )
}

export default function Tutorials({}: Props): ReactElement {
  const [search, setSearch] = React.useState("")
  return (
    <DefaultLayout>
      <Seo title="Tutorials" description="Learn more about matters, damages calculator and eco loss analyser."/>
      <div className="mt-4 min-h-[80vh] px-4">
        <h1 className="text-br-primary-blue text-2xl lg:text-4xl font-bold text-center">
          Tutorials
        </h1>
        <div className="text-br-primary-blue text-xl lg:text-2xl text-center mt-4 flex items-center justify-center flex-wrap">
          Find our tutorials on{" "}
          <a
            href="https://www.youtube.com/channel/UCpMNFMV4s94YAYvR1G1AFdw/playlists"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="h-5 lg:h-7 mx-2 object-cover"
              src={Youtube}
              alt={"Youtube"}
            />{" "}
          </a>
          or click through the menu below.
        </div>

        <div className="max-w-[250px] mx-auto my-6">
          <Input
            className="!border-br-primary-blue focus:!border-transparent !rounded-full"
            placeholder="Search"
            type="search"
            value={search}
            onChange={({ target: { value } }) => {
              setSearch(value)
            }}
          />
        </div>

        <div className="max-w-[800px] mx-auto mt-6">
          {tutorialsList
            .filter(x => {
              return (
                x.title.toLowerCase().includes(search.toLowerCase()) ||
                x.tutorials.some(
                  x =>
                    x.title.toLowerCase().includes(search.toLowerCase()) ||
                    x.steps?.toLowerCase().includes(search.toLowerCase())
                )
              )
            })
            .map(tutorial => (
              <Accordion
                key={tutorial.title + search}
                title={tutorial.title}
                search={search}
              >
                {tutorial.tutorials
                  .filter(
                    x =>
                      x.title.toLowerCase().includes(search.toLowerCase()) ||
                      x.steps?.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((tutorial, index) => (
                    <Accordion
                      key={tutorial.title + search}
                      title={index + 1 + ". " + tutorial.title}
                      small
                      search={search}
                    >
                      {tutorial.video && (
                        <div>
                          <div className="rounded-lg mt-6 h-[250px] sm:h-[450px] w-[800px] overflow-hidden max-w-[100%] relative">
                            <MediaRender media={tutorial.video} />
                          </div>
                          {tutorial.steps && (
                            <div
                              className="tutorial"
                              dangerouslySetInnerHTML={{
                                __html: tutorial.steps,
                              }}
                            ></div>
                          )}
                        </div>
                      )}
                    </Accordion>
                  ))}
              </Accordion>
            ))}
        </div>
      </div>
    </DefaultLayout>
  )
}
